<template>
  <div class="explain-cont ignore-about">
    <img src="@/assets/image/about.jpg" alt="">
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    
  },
  methods: {
    goBack () {
      this.$router.back(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.ignore-about{
  max-width: 700px;
  margin: 0 auto;
}
.explain-cont{
  min-height: 100.1vh;
  box-sizing: border-box;
  padding: 50px 0;
  img{
    display: block;
    width: 100%;
    pointer-events:none;
  }
}
</style>
